import { FetchKeys } from '@constants/queries';
import { IUserProfile } from '@customTypes/response/IUser';
import useAuth from '@hooks/useAuth';
import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getUserProfile } from '@services/user';

function useProfileDetails({
  enabled = false,
  onSuccess,
  refetchOnWindowFocus = true,
}: PromiseCallbacks) {
  const { user } = useAuth();
  return useFetch<IUserProfile | undefined>(
    [FetchKeys.profileDetails],
    () => getUserProfile(),
    {
      onSuccess,
      enabled: user.isEmployee || enabled,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus,
    },
  );
}

export default useProfileDetails;
