import NetworkClient from '@services/Network';
import { IEmergencyFormData } from 'src/view/screens/EmergencyContact/utils/types';
import { beautifyUrl } from '@utilities/common/Path';
import Config from '../config';

const ErrorMessages = {
  genericAdd:
    'Unable to add the emergency contatct person details, please try again!',
  genericRemove:
    'Unable to remove the emergency contatct person details, please try again!',
  genericGet:
    'Unable to fetch the emergency contatct person details, please try again!',
};

const client = new NetworkClient();

export const addECPDetails = async (
  body: IEmergencyFormData['contactDetails'],
) => {
  const response = await client.doPost(Config.api.ecpRelation.addECP, body);
  if (response.success) {
    return response.data;
  }

  throw new Error(
    response.error?.message || response.details || ErrorMessages.genericAdd,
  );
};

export const removeECPDetails = async (ecpId: string) => {
  const response = await client.doDelete(
    beautifyUrl(Config.api.ecpRelation.deleteECP, [ecpId]),
  );
  if (response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || response.details || ErrorMessages.genericRemove,
  );
};

export const getECPDetails = async () => {
  const response = await client.doGet(Config.api.ecpRelation.getECP);
  if (response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || response.details || ErrorMessages.genericGet,
  );
};
