import { FetchKeys } from '@constants/queries';
import { CheckConsentRequest, ConsentUpdateRequest } from '@customTypes/request/common';
import { EmployeeConsentData } from '@customTypes/response/IUser';
import { useFetch } from '@hooks/useFetch';
import { checkConsent, updateConsent } from '@services/consent';
import { useMutation } from 'react-query';

function useConsentCheck(data: CheckConsentRequest, enabled: boolean, onSuccess?: (data: EmployeeConsentData | undefined) => void) {
  const { country, email, organisationId } = data;
  return useFetch<EmployeeConsentData>(
    [FetchKeys.EmployeeConsentData, country, email, organisationId],
    () => checkConsent(data),
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
      onSuccess,
    },
  );
}

function useUpdateConsent() {
  return useMutation((data: ConsentUpdateRequest) => updateConsent(data));
}

export { useConsentCheck, useUpdateConsent };
