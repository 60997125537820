import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getECPDetails } from '@services/ECPRelation';
import { IECPGetRespType } from 'src/view/screens/EmergencyContact/utils/types';

function useGetEmergencyContactsList({
  onSuccess,
  refetchOnWindowFocus = false,
  enabled = false,
}: PromiseCallbacks) {
  return useFetch<IECPGetRespType[]>(
    ['getECPData'],
    getECPDetails,
    {
      enabled,
      onSuccess,
      refetchOnWindowFocus,
    },
  );

}

export default useGetEmergencyContactsList;
