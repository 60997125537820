import { BookingSources } from '@constants/App';
import { ClientTiers, TikTokOrganizationID } from '@constants/user';
import { ClientCreditData, IUserProfile } from '@customTypes/response/IUser';
import { UserWithRoles } from '@hooks/useAuth/useAuth';
import { isProduction } from './Path';

export const checkUserBookingAbility = (
  creditData: ClientCreditData | undefined,
) => {
  let bookingAbility = {
    canBookRecurringSession: false,
    canBookSingleSession: false,
  };

  const { credit = 0, canBook, tier } = creditData || {};

  if (tier === ClientTiers.UNLIMITED) {
    bookingAbility = {
      ...bookingAbility,
      canBookRecurringSession: true,
      canBookSingleSession: true,
    };
  }

  if (canBook && credit === 0) {
    bookingAbility = {
      ...bookingAbility,
      canBookRecurringSession: true,
      canBookSingleSession: true,
    };
  }

  if (credit > 1) {
    bookingAbility = {
      ...bookingAbility,
      canBookRecurringSession: true,
      canBookSingleSession: true,
    };
  } else if (credit !== 0) {
    bookingAbility = {
      ...bookingAbility,
      canBookRecurringSession: false,
      canBookSingleSession: true,
    };
  }

  return bookingAbility;
};

export const getTikTokOrganizationIDforConsent = () =>
  isProduction() ? TikTokOrganizationID.PROD : TikTokOrganizationID.STAGING;

// removing check for age and tenure as they are optional fields now
export const hasValidProfileData = (profileData: IUserProfile | undefined) =>
  profileData && profileData.phoneNumber && profileData.misc?.region;

export const getBookingSource = (user: UserWithRoles) => {
  if (user.isEmployee) {
    return BookingSources.maverick;
  }

  if (user.isResponder) {
    return BookingSources.responder;
  }

  if (user.isCareNavigator) {
    return BookingSources.carenav;
  }

  return BookingSources.maverick;
};
