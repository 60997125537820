import { AxiosRequestHeaders } from 'axios';
import NetworkClient from '@services/Network';
import { addItemToLocalStorage, getItemFromLocalStorage } from '@utilities/common/Storage';
import { beautifyUrl } from '@utilities/common/Path';
import { SendMessageTypes } from '../../data-types/ClientTypes';
import Config from '../../../../../shared/config';


export const errorMessage = {
  generic: 'Unable to load the data, please try again!',
};

export function isIrisTokenExpired(expToken: number) {
  // Parse the expiration date and time from the expToken
  const expirationTime = new Date(expToken);

  // Compare the current time to the expiration time
  const currentTime = new Date();
  if (currentTime.getTime() >= expirationTime.getTime()) {
    // The token has expired
    return true;
  }
  return false;
}

export const getIrisAccessToken = async (client: NetworkClient) => {
  const response = await client.doGet(Config.api.iris.getIrisAccessToken);
  if (response && response.data) {
    addItemToLocalStorage('irisExpiryTime', response.data.exp);
    addItemToLocalStorage('irisAccessToken', response.data.token);
    return response.data;
  }

  throw new Error('Error');
};

export function getIrisApiHeader(): Partial<AxiosRequestHeaders> {
  const headers: Partial<AxiosRequestHeaders> = {
    Authorization: getItemFromLocalStorage('irisAccessToken', 'string').toString(),
    'Content-Type': undefined,
  };
  return headers;
}

export const sendIrisMessage = async (client: NetworkClient, { data, conversationId }: SendMessageTypes) => {
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  if (isIrisTokenExpired(Number(expToken))) {
    // const { token }: any = getItemFromLocalStorage(StorageItems.USER_INFO, 'object');
    await getIrisAccessToken(client);
  }
  const response = await client.doPost(beautifyUrl(Config.api.iris.sendIrisMessage, [conversationId]), 
    data,
    getIrisApiHeader(),
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};

export const getIrisMessages = async (client: NetworkClient, conversationId: string, page: string) => {
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  if (isIrisTokenExpired(Number(expToken))) {
    // const { token }: any = getItemFromLocalStorage(StorageItems.USER_INFO, 'object');
    await getIrisAccessToken(client);
  }
  const response = await client.doGet(
    beautifyUrl(Config.api.iris.getIrisMessages, [conversationId, page]),
    getIrisApiHeader(),
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};

export const getIrisProviderConversationList = async (client: NetworkClient) => {
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  if (isIrisTokenExpired(Number(expToken))) {
    // const { token }: any = getItemFromLocalStorage(StorageItems.USER_INFO, 'object');
    await getIrisAccessToken(client);
  }
  const response = await client.doGet(Config.api.iris.getIrisConversations, getIrisApiHeader());
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.data?.error?.message || errorMessage.generic);
};
